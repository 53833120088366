html, body, #root {
  height: 100%;
  width: 100%;
}

body {
  background: var(--background-color) !important;
  padding-right: 0!important;
  overflow-x: hidden;
}

* {
  outline: 0;
}
